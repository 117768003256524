@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(/public/Roboto-Regular.ttf) format('truetype');
  }


  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    font-family: 'Roboto';
    overflow: auto;
    padding:0;
    margin:0;
    background: #98b29f;
    z-index: 0;
    }

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #293d36 #dce5df;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 20px;
  }

  *::-webkit-scrollbar-track {
    background: #dce5df;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #293d36;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  /* Désactivation de la barre de défilement sur les écrans mobiles */
@media screen and (max-width: 768px) {
  /* Pour les navigateurs Webkit comme Chrome, Safari et Edge */
  *::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  * {
    scrollbar-width: none;
  }
}