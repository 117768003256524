  .divGen {
      background-image: url("../../images/212sky.jpg") !important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      overflow: auto;
      position: absolute;
      z-index: 0;
     

      
  }



  /* .three {
    
  
      


  } */

  .title {
      font-family: 'Roboto';
      font-weight: bold;
      color: #DCE5DF;
      position: absolute;




  }

  #cloud_three {
      position: absolute;
    
  }


  /* .carousel{


  }   */

  #cloud_carousel{
    margin-left:-3%; 
    margin-top: -3%;
    width: 30%;
  }

  /* #airballoon{
  

  } */

  #quotebox{
    margin-top: -12%;
    text-align: left;
    margin-left: auto;
    margin-right: auto ;
    z-index: 1;
    width: 96%;
    
  }

/* #contactForm{
} */